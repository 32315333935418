import { Controller } from 'stimulus'


export default class extends Controller {
  initialize() {
    this.element.addEventListener('input', this.autosize.bind(this));
  }

  connect() {
    this.initialHeight = this.data.get('initial-height') || this.element.offsetHeight;
    // Let's save the initial height in case the element gets moved to other place and connected again
    this.element.setAttribute('data-autosize-initial-height', this.initialHeight);
    this.autosize();
  }

  autosize() {
    if (this.element.style.height !== this.element.scrollHeight + "px") {
      this.element.style.height = this.initialHeight + "px";
      this.element.style.height = this.element.scrollHeight + "px";

      // TODO: Look if there is a better way to do this
      var evt = new CustomEvent('export:pages-mutated');
      document.dispatchEvent(evt);
    }
  }
}
