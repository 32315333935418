import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  initialize() {
    this.hiddenClass = 'd-none'
  }

  connect() {
    this.sectionOne = document.querySelector(this.data.get('section-one'))
    this.sectionTwo = document.querySelector(this.data.get('section-two'))
    this.disableInputs = this.data.get('disable-inputs') === 'true'
  }

  switch(e) {
    e.preventDefault()
    this.element.classList.toggle('active')

    if (this.sectionOne.classList.contains(this.hiddenClass)) {
      this.showSection(this.sectionOne)
      this.hideSection(this.sectionTwo)
    } else if (this.sectionTwo.classList.contains(this.hiddenClass)) {
      this.showSection(this.sectionTwo)
      this.hideSection(this.sectionOne)
    } else {
      this.hideSection(this.sectionOne)
    }
  }

  hideSection(section) {
    section.classList.add(this.hiddenClass)
    if (this.disableInputs) {
      $(section).find(':input').prop('disabled', true)
    }
  }

  showSection(section) {
    section.classList.remove(this.hiddenClass)
    if (this.disableInputs) {
      $(section).find(':input').prop('disabled', false)
    }
  }
}
