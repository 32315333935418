import { Controller } from "stimulus"

/**
 * Animates the display of a collapsible element
 *
 * Attached element:
 *  Use the toggle method in the attached element to toggle the collapsible target
 *  The 'active' class is set when the collapsible target is shown
 *
 * Collapsible Target:
 *  Configure the target of the toggle button setting the data-toggle-collapsible-target,
 *  example: data-toggle-collapsible-target="#domelement".
 *  The target collapsible element must have the 'collapsible' class.
 *  Use the 'show' class as well to display the element the first time
 *
 * Active Target:
 *  Configure the active target so that it would also get the active class on active
 *  example: data-toggle-collapsible-active-target="#domelement".
 *
 * Animation phases:
 *  .collapsible -> Idle animation (Element hidden)
 *  .collapsible.collapsing -> Start of the animation
 *  .collapsible.collapsing.show -> Element being animated
 *  .collapsible.show -> Animation finished (Element displayed)
 */
export default class extends Controller {

  initialize() {
    this.activeClass = 'active'
  }

  connect() {
    this.toggleTarget = document.querySelector(this.data.get('target'))
    this.activeTarget = document.querySelector(this.data.get('active-target'))
    if (this.toggleTarget.classList.contains('show')) {
      this.element.classList.add(this.activeClass)
      if (this.activeTarget) {
        this.activeTarget.classList.add(this.activeClass)
      }
    }
    this.toggleTarget.addEventListener("transitionend", () => {
      this.toggleTarget.classList.remove('collapsing')
      this.toggleTarget.style.height = ''
    });
  }
  
  toggle(event) {
    let hide = event
    if (typeof hide !== 'boolean') {
      event.preventDefault()
      hide = this.toggleTarget.classList.contains('show')
    }
    if (hide) {
      this.toggleTarget.style.height = this.toggleTarget.scrollHeight + 'px'
      this.element.classList.remove(this.activeClass)
      if (this.activeTarget) {
        this.activeTarget.classList.remove(this.activeClass)
      }
    } else {
      this.toggleTarget.style.height = '0px'
      this.element.classList.add(this.activeClass)
      if (this.activeTarget) {
        this.activeTarget.classList.add(this.activeClass)
      }
    }

    // Begin transition
    this.toggleTarget.classList.add('collapsing')
    requestAnimationFrame(() => {
      if (hide) {
        this.toggleTarget.style.height = '0px'
        this.toggleTarget.classList.remove('show')
      } else {
        this.toggleTarget.style.height = this.toggleTarget.scrollHeight + 'px'
        this.toggleTarget.classList.add('show')
      }
    })
  }

  show() {
    this.toggle(false)
  }

  hide() {
    this.toggle(true)
  }
}
