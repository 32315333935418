import { Controller } from "stimulus"

const upKey = 38
const downKey = 40
const enterKey = 13
const navigationKeys = [upKey, downKey, enterKey]

export default class extends Controller {
  static targets = [ "result" ]

  connect() {
    this.currentResultIndex = 0
    this.selectCurrentResult()
  }

  navigateResults(event) {
    if(!navigationKeys.includes(event.keyCode)) return
    event.preventDefault()

    switch(event.keyCode) {
      case downKey:
        this.selectNextResult()
        break;
      case upKey:
        this.selectPreviousResult()
        break;
      case enterKey:
        this.goToSelectedResult()
        break;
    }
  }

  hover(e) {
    this.resultTargets.forEach((element, index) => {
      let toggle = element == e.target || element.querySelector('a') == e.target

      element.classList.toggle("search__result--current", toggle)
      if (toggle) {
        this.currentResultIndex = index
      }
    })
  }

  selectCurrentResult() {
    this.resultTargets.forEach((element, index) => {
      element.classList.toggle("search__result--current", index == this.currentResultIndex)
      if (index == this.currentResultIndex) {
          this.currentResultIndex = index
      }
    })
  }

  selectNextResult() {
    if(this.currentResultIndex < this.resultTargets.length - 1) {
      this.currentResultIndex++
      this.selectCurrentResult()

      this.moveScrollOfList()
    }
  }

  selectPreviousResult() {
    if(this.currentResultIndex > 0) {
      this.currentResultIndex--
      this.selectCurrentResult()

      this.moveScrollOfList()
    }
  }

  goToSelectedResult() {
    this.resultTargets[this.currentResultIndex].querySelector('a').click()
  }

  /**
   * move the scroll of the displayed list to a position
   * where the current item selected will always be on display.
   */
  moveScrollOfList() {
    var currentItemSelected = this.resultTargets[this.currentResultIndex]
    var list = currentItemSelected.parentElement

    var currentElementBottom = currentItemSelected.offsetTop + currentItemSelected.clientHeight
    var currentListBottom = list.scrollTop + list.clientHeight

    var elementIsAboveScroll = currentItemSelected.offsetTop < list.scrollTop
    var elementIsUnderScroll = currentElementBottom > currentListBottom

    if (elementIsAboveScroll) {
      list.scrollTop = currentItemSelected.offsetTop
    } else if (elementIsUnderScroll) {
      list.scrollTop = currentElementBottom - list.clientHeight
    }
  }
}
