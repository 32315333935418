import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    window.onbeforeunload = this.leavingPage.bind(this);
  }

  connect() {
    this.element.addEventListener("change", () => {
      this.data.set("saved", "false");
    })
  }

  leavingPage(event) {
    if(this.data.get("saved") === "false") {
      return false;
    }
  }
}
