import { KPIButton } from "./kpi_button";
import { createPopper } from "@popperjs/core";
import $ from 'jquery';

export class PositionedButtons {
  /**
   * 
   * @param {number} position 
   * @param {KPIButton.types} type 
   * @param {Array<KPIButton>} elements
   */
  constructor(position, type, elements) {
    this.position = position;
    this.type = type;
    this.elements = elements ? elements : [];
    this.listElement = this.buildListElement();

    this.listTimeoutTime = 250;
    this.listTimeout;

    this.clickHandlers = [];
    this.mouseEnterHandlers = [];
    this.mouseLeaveHandlers = [];
    document.addEventListener("showing-list", this.handleListShow.bind(this));
  }

  /**
   * Allows only one list in the DOM at a time.
   * 
   * @param {CustomEvent} event 
   */
  handleListShow(event) {
    if (event.detail != this.listElement) {
      this.listElement.remove();
    }
  }

  /**
   * Adds a new button to the elements array.
   * 
   * @param {KPIButton} element 
   */
  addElement(element) {
    if (element.type != this.type) return;

    this.elements.push(element);
    this.rebuildList();
  }

  /**
   * Removes a button element from the elements array.
   * 
   * @param {KPIButton} element 
   */
  removeElement(element) {
    const index = this.elements.indexOf(element);
    this.elements.splice(index, 1);
    this.rebuildList();
  }

  /**
   * Removes the list element from the DOM and
   * build the list again.
   */
  rebuildList() {
    this.listElement.remove();
    this.listElement = this.buildListElement();
  }

  /**
   * Attach the click, mouseenter and mouse leave events to all
   * buttons contained in the elements property.
   * 
   * @param {Function} singleItemCallback 
   */
  attachEventListeners(singleItemCallback) {
    for (let i = 0; i < this.elements.length; i++) {
      const button = this.elements[i].button;

      this.mouseEnterHandlers.push(this.createMouseEnterHandler(button));
      this.mouseLeaveHandlers.push(this.createMouseLeaveHandler());
      this.clickHandlers.push(this.createClickHandler(button, singleItemCallback));

      button.addEventListener("click", this.clickHandlers[i]);
      button.addEventListener("mouseenter", this.mouseEnterHandlers[i]);
      button.addEventListener("mouseleave", this.mouseLeaveHandlers[i]);
    }
  }

  /**
   * Creates and return a function to handle the mouse enter event
   * on a given button.
   * 
   * @param {HTMLElement} button floating button
   */
  createMouseEnterHandler(button) {
    return function (event) {
      button.appendChild(this.listElement);
      event.preventDefault();


      createPopper(button, this.listElement, {
        placement: this.type == KPIButton.types.SHOW ? "right" : "left",
        modifiers: [{ name: "offset", options: { offset: [0, 20] } }]
      });

      clearTimeout(this.listTimeout);
      document.dispatchEvent(new CustomEvent("showing-list", { detail: this.listElement }));
    }.bind(this);
  }

  /**
   * Creates and return a function to handle the mouse leave event
   * which removes the listElement after some time.
   */
  createMouseLeaveHandler() {
    return function () {
      this.listTimeout = setTimeout(() => this.listElement.remove(), this.listTimeoutTime);
    }.bind(this);
  }

  /**
   * Creates and return a function to handle the click event
   * on a given button.
   * 
   * @param {HTMLElement} button floating button
   * @param {Function} callback
   */
  createClickHandler(button, callback) {
    return function (event) {
      callback(event);
      this.removeElement(button);
      event.preventDefault();
    }.bind(this);
  }

  /**
   * Removes all event listeners from all the buttons.
   */
  disposeListeners() {
    for (let i = 0; i < this.elements.length; i++) {
      const button = this.elements[i].button;
      const clickHandler = this.clickHandlers[i];
      const mouseEnterHandler = this.mouseEnterHandlers[i];
      const mouseLeaveHandlers = this.mouseLeaveHandlers[i];

      button.removeEventListener("click", clickHandler);
      button.removeEventListener("mouseenter", mouseEnterHandler);
      button.removeEventListener("mouseleave", mouseLeaveHandlers);
    }
    document.removeEventListener("showing-list", this.handleListShow);
  }

  /**
   * Creates a list with all the kpi buttons
   * 
   * @returns {HTMLUListElement} List
   */
  buildListElement() {
    const listElement = document.createElement("UL");
    listElement.classList.add("floating-list", "shadow-sm");

    for (const kpiButton of this.elements) {
      const listItem = $($.parseHTML(`
        <li>
          <span class="floating-list__title text-clear">${kpiButton.name}</span>
          <p class="floating-list__value">${kpiButton.value}</p>
        </li>
      `))[1];

      listItem.addEventListener("click", e => {
        e.preventDefault();
        e.stopPropagation();

        kpiButton.button.click()
      });
      listElement.appendChild(listItem);
    }

    return listElement;
  }
}
