import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "info", "files" ]

  connect() {
    this.inputTarget.addEventListener('change', event => this.inputOnChange(event))
    this.inputTarget.addEventListener('click', event => this.inputClick(event))

    try {
      this.filesTarget.addEventListener('click', event => event.stopPropagation())
    } catch (e) {/* Do nothing as filesTarget is not required */}

    this.clearFilesAfterSelect = this.data.get('clear-files') === 'true'
  }

  selectFiles(e) {
    this.inputTarget.click()
  }

  inputClick(e) {
    e.stopPropagation()
  }

  inputOnChange(e) {
    const length = this.inputTarget.files.length
    let info = ""

    for (var i = 0; i < length; i++) {
      if (i > 2 && length > 3) {
        info += ` +${length - 3}`
        break
      }
      info += ` ${this.inputTarget.files[i].name},`
    }

    if (info.endsWith(',')) {
      info = info.slice(0, info.length - 1)
    }
    this.infoTarget.innerHTML = info

    if (this.clearFilesAfterSelect && this.filesTarget) {
      this.filesTarget.innerHTML = ""
    }
  }
}
