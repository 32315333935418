import { Controller } from "stimulus"

const upKey = 38
const downKey = 40
const enterKey = 13
const escapeKey = 27
const navigationKeys = [upKey, downKey, enterKey, escapeKey]

export default class extends Controller {

  /**
   * Declares the required variables to make the controller
   * functional and also sets the listeners for the
   * input fields.
   */
  connect() {
    this.currentListIndex = -1
    this.currentAddressIndex = -1
    this.currentInputElement = null
    this.currentListElement = null

    this.currentInputValues = []
    this.form_scope = this.data.get('scope')

    this.json_data = JSON.parse(this.data.get('json'))
    this.addListenerToInputs()
    this.updateCurrentInputValues()  
  }

  /**
   * Adds a List Element next to the targeted input with its listeners.
   * 
   * @param {FocusEvent} event 
   */
  focused(event) {
    this.currentInputElement = event.target
    this.insertListInDOM()
  }

  /**
   * Mouse moved over an item element triggers this
   * functions which selects an item from the list.
   * 
   * @param {MouseMoveEvent} event 
   */
  mouseMoveOverElement(event) {
    var itemElement = event.target
    var itemAddressIndex = itemElement.getAttribute('address-index')

    /* avoids that the inside <p> elements gets to the inner code */
    if (itemElement.nodeName == "LI" && itemAddressIndex != this.currentAddressIndex) {
      this.selectSuggestion(itemElement)
    
      this.currentAddressIndex = itemAddressIndex
      this.setSuggestions()
    }
  }


  /**
   * If the user leaves the list without clicking,
   * then inputs are restored to their original values.
   * 
   * @param {MouseOutEvent} event 
   */
  mouseLeaveList(event) {
    this.resetInputs()
  }

  /**
   * Removes the current List Element from the DOM
   * and resets indexes.
   */
  unfocused() {
    this.currentListElement.remove()
    this.updateCurrentInputValues()
    this.resetIndexes()
  }

  /**
   * navigates through the suggestions if the current
   * keycode is included in the navigationKeys.
   * 
   * @param {KeydownEvent} event 
   */
  navigateSuggestions(event) {
    if (!navigationKeys.includes(event.keyCode)) {
      this.handleNonNavigationKeys()
      return
    }
    event.preventDefault()

    if (!document.body.contains(this.currentListElement) 
        || this.currentListSize() == 0
        || this.currentListElement.style.display == 'none') {
      
      /* If the user has an empty after pressing the escape key, and now
         he wants to have again the suggestion list by pressing the downkey
         or the upkey, then the following sentence make it possible */ 
      if ((event.keyCode == downKey || event.keyCode == upKey) && !document.body.contains(this.currentListElement)) {
        this.insertListInDOM()
      } else {
        return
      }
    } 
    
    switch(event.keyCode) {
      case downKey:
        this.selectNextSuggestion()
        break;
      case upKey:
        this.selectPreviousSuggestion()
        break;
      case enterKey:
        this.unfocused()
        this.updateCurrentInputValues()
        break;
      case escapeKey:
        this.resetInputs()
        this.unfocused()
        break;
    }
  }

  handleNonNavigationKeys() {
    this.resetInputs(this.currentInputElement.getAttribute('name'), true)

      if (document.body.contains(this.currentListElement)) {
        this.updateList()
      } else {
        /* if the body does not contain a current list, this is created
           again. This happens when the user selects a suggestions or
           cancels the suggestions (enter or escape keys) and he starts
           typing again. */
        this.insertListInDOM()
      }

      this.currentInputElement.classList.remove('active')
      this.removeCurrentItemSelectedClass()
      this.resetIndexes()
      this.updateCurrentInputValues()
  }
  
  /**
   * Selects next suggestion in list and adds a class
   * of selection to that suggestion.
   */
  selectNextSuggestion() {
    this.removeCurrentItemSelectedClass()

    if (this.currentListSize() > this.currentListIndex + 1) {
      this.currentListIndex += 1
    } else {
      this.currentListIndex = 0      
    }

    this.updateSuggestions()
  }

  /**
   * Selects previous suggestion in list and adds a class
   * of selection to that suggestion.
   */
  selectPreviousSuggestion() {
    this.removeCurrentItemSelectedClass()

    if (this.currentListIndex - 1 >= 0) {
      this.currentListIndex -= 1
    } else {
      this.currentListIndex = this.currentListSize() - 1
    }

    this.updateSuggestions()
  }

  /**
   * Select the given itemElement in the list.
   * 
   * @param {liElement} itemElement 
   */
  selectSuggestion(itemElement) {
    for (var i = 0; i < this.currentListSize(); i++) {
      var listItem = this.currentListElement.children[i]
      
      if (itemElement == listItem) {
        listItem.classList.add('autocomplete__selected')
        this.currentListIndex = i
      } else {
        listItem.classList.remove('autocomplete__selected')
      }
    }
  }

  /**
   * Sets the suggestion selected in each input field and adds
   * a active class to each of these inputs. 
   */
  setSuggestions() {
    for (var entry in this.json_data) {
      var inputName = `${this.form_scope}[${entry}]`
      var value = this.json_data[entry].filter(value => value.index == this.currentAddressIndex)[0]

      if (value != undefined) {
        var inputElement = document.querySelector(`input[name="${inputName}"]`)
        inputElement.value = value.value
        inputElement.classList.add('active')
      }
    }
  }

  /**
   * reset inputs to their original values.
   */
  resetInputs(inputNameException, notRemoveClass) {
    for (var entry in this.json_data) {
      var inputName = `${this.form_scope}[${entry}]`
      var inputElement = document.querySelector(`input[name="${inputName}"]`)

      if (inputName == inputNameException) {
        continue
      }

      inputElement.value = this.currentInputValues.filter(element => element.inputName == inputName)[0].value
      
      if (!notRemoveClass) {
        inputElement.classList.remove('active')
      }
    }
  }

  /**
   * Saves the current input values in an array
   * so they can be obtained in case that the user
   * cancels the suggestion selection
   */
  updateCurrentInputValues() {
    this.currentInputValues = []
    for (var entry in this.json_data) {
      var inputName = `${this.form_scope}[${entry}]`
      var inputElement = document.querySelector(`input[name="${inputName}"]`)

      var data = {
        inputName: inputName,
        value: inputElement.value
      }
      this.currentInputValues.push(data)
    }
  }

  updateSuggestions() {
    this.currentAddressIndex = this.currentItemSelected().getAttribute('address-index')

    this.currentItemSelected().classList.add('autocomplete__selected')
    this.setSuggestions()

    this.moveScrollOfList()
  }

  /**
   * Set listeners of type focus, blur and keydown to all the inputs
   * listed in the json_data and also saves current input values.
   */
  addListenerToInputs() {
    for (var entry in this.json_data) {
      var inputName = `${this.form_scope}[${entry}]`
      var inputElement = document.querySelector(`input[name="${inputName}"]`)

      inputElement.addEventListener('focus', event => this.focused(event))
      inputElement.addEventListener('blur', event => this.unfocused(event))
      inputElement.addEventListener('keyup', event => this.navigateSuggestions(event))

      /* Required in order to prevent form saving when pressing enter */
      inputElement.addEventListener('keydown', event => { if (event.keyCode == enterKey) event.preventDefault() })
    }
  }

  /**
   * Sets a event listener of type click to each children
   * in the list.
   */
  addMouseListenersToList() {
    for (var children of this.currentListElement.children) {
      children.addEventListener('mousemove', event => this.mouseMoveOverElement(event))
    }

    this.currentListElement.onmouseleave = event => this.mouseLeaveList(event)
  }

  resetIndexes() {
    this.currentListIndex = -1
    this.currentAddressIndex = -1
  }

  /**
   * Shorthand to obtain the number of children in the
   * current list.
   */
  currentListSize() {
    return this.currentListElement.children.length
  }

  /**
   * Shorthand to obtain the current <li> element selected
   * in the current list.
   */
  currentItemSelected() {
    return this.currentListElement.children[this.currentListIndex]
  }

  /**
   * Shorthand to remove the class 'autocomplete__selected'
   * from the current item selected in the current list.
   */
  removeCurrentItemSelectedClass() {
    if (this.currentListIndex >= 0 && this.currentItemSelected()) {
      this.currentItemSelected().classList.remove('autocomplete__selected')
    }
  }


  /**
   * move the scroll of the displayed list to a position
   * where the current item selected will always be on display.
   */
  moveScrollOfList() {
    var currentElementBottom = this.currentItemSelected().offsetTop + this.currentItemSelected().clientHeight
    var currentListBottom = this.currentListElement.scrollTop + this.currentListElement.clientHeight

    var elementIsAboveScroll = this.currentItemSelected().offsetTop < this.currentListElement.scrollTop
    var elementIsUnderScroll = currentElementBottom > currentListBottom

    if (elementIsAboveScroll) {
      this.currentListElement.scrollTop = this.currentItemSelected().offsetTop
    } else if (elementIsUnderScroll) {
      this.currentListElement.scrollTop = currentElementBottom - this.currentListElement.clientHeight
    }
  }


  /**
   * Creates an <ul> element with suggestions as
   * <li> children.
   */
  generateListElement() {
    var ulElement = document.createElement("ul")
    ulElement.classList.add("autocomplete-list", "shadow")

    var liElements = this.generateListItems()

    for (var liElement of liElements) {
      ulElement.append(liElement)
    }

    return ulElement
  }

  /**
   * Generates the <li> children but only those whose
   * text content is related to the current input.
   */
  generateListItems() {
    var liElements = []
    var suggestions = this.findSuggestionsByInputName()
    var inputValue = this.currentInputElement.value.toLowerCase()

    for (var suggestion of suggestions) {
      if (suggestion.value.toLowerCase().indexOf(inputValue) < 0) {
        continue
      }
      
      var liElement = document.createElement("li")
      liElement.textContent = suggestion.value
      liElement.setAttribute('address-index', suggestion.index)
      liElement.classList.add("text-truncate")

      var pElement = document.createElement("p")
      pElement.textContent = this.generateFullAddress(suggestion.index)
      pElement.classList.add("text-truncate", "full-address")

      liElement.append(pElement)
      liElements.push(liElement)
    }

    return liElements
  }

  updateList(inputValue) {
    this.currentListElement.remove()
    this.insertListInDOM()
  }


  insertListInDOM() {
    this.currentListElement = this.generateListElement()
    this.currentInputElement.insertAdjacentElement("afterend", this.currentListElement)
    
    if (this.currentListSize() > 0) {
      this.currentListElement.style.display = 'block'
      this.addMouseListenersToList()
    }
  }

  generateFullAddress(addressIndex) {
    var fullAddress = ""

    for (var entry in this.json_data) {
      for (var suggestion of this.json_data[entry]) {
        if (suggestion.index == addressIndex) {
          fullAddress += suggestion.value + " "
        }
      }
    }

    return fullAddress.trim() // removes last space character
  }

  /**
   * Find suggestions according to the current input name attribute.
   */
  findSuggestionsByInputName() {
    var scopeLenght = this.form_scope.length
    var inputName = this.currentInputElement.name.slice(scopeLenght + 1, -1)

    return this.json_data[inputName]
  }

}
