// This is a custom javascript for the export to PDF feature (Two Pager).
// This cannot be a stimulus controller due to the lack of modern ECMASCRIPT into the wkhtmkltopdf binary required by the stimulus controller
// There is a lot of stuff that simple doesn't work in wkhtmkltopdf, that's why we use jQuery along with vanilla JS for some stuff
import jQuery from 'jquery'

const A4Height = 1122;
const domChangesTimeout = 10;
const $ = jQuery;

const EXPORT = {
  arrangePages: function () {
    let sendItemsToNextPage = function (page, nextPage) {
      let controlIndex = 0; // To avoid infinite cycle
      while (controlIndex < 40 && page.scrollHeight > A4Height) {
        let kpi = $(page).find('[data-export="kpi"]').last();
        let nextPageTarget = $(nextPage).find('[data-export="kpis"]');
        nextPageTarget[0].insertAdjacentElement('afterbegin', kpi[0]);
        controlIndex++;
      }
      $(nextPage).removeClass('d-none');
    }

    let bringItemsFromNextPage = function (page, nextPage) {
      const currentPageTarget = $(page).find('[data-export="kpis"]');

      while (page.scrollHeight <= A4Height) {
        const nextPageKpis = $(nextPage).find('[data-export="kpi"]');
        if (!nextPageKpis[0]) break;

        currentPageTarget[0].insertAdjacentElement('beforeend', nextPageKpis[0]);
      }

      sendItemsToNextPage(page, nextPage);

      const nextPageKpis = $(nextPage).find('[data-export="kpi"]');
      if (nextPageKpis.length === 0) $(nextPage).addClass('d-none')
    }

    let pages = $('[data-export="page"]');
    for (let i = 0; i < pages.length; i++) {
      let page = pages[i];
      let nextPage = i + 1 < pages.length ? pages[i + 1] : null;
      if (nextPage === null) break;

      if (page.scrollHeight > A4Height) {
        sendItemsToNextPage(page, nextPage);
      } else {
        bringItemsFromNextPage(page, nextPage);
      }
    }

    document.dispatchEvent(new CustomEvent('export:items-updated'));
  },
  onPagesMutated: function () {
    EXPORT.arrangePages();
  }
};

window.EXPORT = EXPORT;

document.addEventListener('DOMContentLoaded', function () {
  setTimeout(function () {
    EXPORT.arrangePages();
    document.addEventListener('export:pages-mutated', EXPORT.onPagesMutated);
  }, domChangesTimeout);
});
