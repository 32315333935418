import { Controller } from "stimulus"

export default class extends Controller {
  close(e) {
    e.preventDefault()
    this.element.remove()
  }

  // Useful for buttons that submits forms as well
  delayedClose() {
    setTimeout(() => {
      this.element.remove()
    }, 100)
  }

  handleKey(e) {
    if (e.keyCode === 27) { // esc
      this.close(e)
    }
  }
}
