/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../src/images'
import '../src/stylesheets/application.scss'
import '../src/javascripts/export'

import Rails from 'rails-ujs'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import "bootstrap" // Bootstrap requires jquery and popper by default (Those are needed for components like dropdown)
import $ from 'jquery'

Rails.start()

const stimulus_application = Application.start()
const stimulus_context = require.context('../src/javascripts/controllers', true, /\.js$/)
stimulus_application.load(definitionsFromContext(stimulus_context))

$(document).ready(function() {
  // Initialize tooltips
  $('[data-toggle="tooltip"]').tooltip({
    placement: (tooltip, el) => {
      return el.getAttribute('data-placement') || 'bottom'
    }
  })
});
