import { Controller } from "stimulus"

/**
 * Clears a form to its first state
 *
 * Form Target:
 *  Configure the target form setting data-form-clearer-form,
 *  example: data-form-clearer-form="#form-id".
 *
 * Clear Form:
 *  Use the clearForm method to clear the form state
 *
 * The has-href config:
 *  By default the clearer button is hidden and displayed when the form receives an input,
 *  in case you want to use the clearer button as a normal button with href,
 *  then set the data-form-clearer-has-href to "true" or just add the attribute to the button
 */
export default class extends Controller {
  initialize() {
    let dataHasHref = this.data.get("has-href")
    this.hasHref = dataHasHref !== null && dataHasHref !== "false"
  }

  connect() {
    this.form = document.querySelector(this.data.get("form"))

    if (!this.hasHref) {
      this.form.addEventListener("input", this.onFormInput.bind(this))
      this.changeClearerDisplay()
    }
  }

  clearForm() {
    if (this.form && !this.hasHref) {
      this.form.reset()
      this.changeClearerDisplay(false)
    }
  }

  changeClearerDisplay(show) {
    this.element.style.display = show ? "inline-block" : "none"
  }

  onFormInput() {
    this.changeClearerDisplay(true)
  }
}
