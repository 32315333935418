import { Controller } from "stimulus";
import Sortable from 'sortablejs';
import { createPopper } from "@popperjs/core";
import $ from 'jquery';
import { KPIButton } from "../two_pager_objects/kpi_button";
import { PositionedButtons } from "../two_pager_objects/positioned_buttons";

export default class extends Controller {
  static targets = ["item"]

  /**
   * array of kpi buttons.
   * 
   * Contains all the current displayed buttons that interact
   * with the hidden items.
   * 
   * @type Array<KPIButton>
   */
  buttons = []

  /**
   * array of PositionedButtons.
   * 
   * @type Array<PositionedButtons>
   */
  positions = []

  initialize() {
    // this.firstReorder()
    this.sortableHandler = this.attachSortable.bind(this)
    document.addEventListener('export:items-updated', this.sortableHandler)
  }

  connect() {
    for (const item of this.itemTargets) {
      const hiddenCheckbox = $(item).find("input[type='checkbox'][name*='hidden']")[0]

      if (hiddenCheckbox && hiddenCheckbox.checked) {
        const stopper = e => e.stopPropagation()
        hiddenCheckbox.addEventListener('change', stopper)

        if (document.readyState == "complete") {
          hiddenCheckbox.checked = false
          hiddenCheckbox.click()

          hiddenCheckbox.removeEventListener('change', stopper)
        } else {
          window.addEventListener('load', () => {
            hiddenCheckbox.checked = false
            hiddenCheckbox.click()

            hiddenCheckbox.removeEventListener('change', stopper)
          })
        }
      }
    }
  }

  disconnect() {
    document.removeEventListener('export:items-updated', this.sortableHandler)
  }

  /**
   * Attaches a sortable behavior to all kpis.
   * 
   * Every time a new item (kpi) is added or removed, it is necessary to
   * re-attach the sortable behavior.
   */
  attachSortable() {
    this.reorderAllItems()

    /* This controller is connected to each page that's gonna be exported.
       As long as a page is empty, there is no first element */
    if (this.itemTargets[0]) {
      if (this.sortableObject) this.sortableObject.destroy()

      this.sortableObject = Sortable.create(this.itemTargets[0].parentElement, {
        draggable: '.draggable-kpi',
        group: 'export-kpis',
        handle: '.scroll-handler',
        onEnd: this.updateItemAfterDrag.bind(this),
        chosenClass: 'dragged-kpi'
      });
    }
  }

  /**
  * Reorder the items with the weights they have after a new request.
  * 
  * The items have a proper weight attribute but are not sorted. What this
  * method does is sort them according to their weight attribute instead of
  * their position in the DOM.
  */
  firstReorder() {
    if (!this.checkWeightsAreSet()) return;

    const allKPIs = document.querySelectorAll("div.row.draggable-kpi")
    let index = 0
    let orderedKPIs = []

    for (const kpiItem of allKPIs) {
      const itemOrder = parseInt(kpiItem.querySelector("input.export-kpi-weight").value)
      if (orderedKPIs.includes(itemOrder)) continue

      if (itemOrder != index) {
        const correctKPI = this.getKPIWithWeightValue(index)
        kpiItem.parentElement.insertBefore(correctKPI, kpiItem)
        orderedKPIs.push(index)
        index++
      }
      index++
    }
  }

  /**
   * In case the weights are not set, the first re-order cannot
   * be done (and shouldn't).
   */
  checkWeightsAreSet() {
    const weightInputs = document.querySelectorAll("input.export-kpi-weight")
    for (const input of weightInputs) {
      if (!input.value) return false;
    }

    return true;
  }

  getKPIWithWeightValue(value) {
    const allKPIs = [...document.querySelectorAll("div.row.draggable-kpi")]
    return allKPIs.find(kpi => kpi.querySelector("input.export-kpi-weight").value == value)
  }

  /**
   * After an item is updated, the event 'export:pages-mutated' is 
   * dispatched to the document in case the reorder of an item implies
   * an overflow in any page.
   */
  updateItemAfterDrag(event) {
    event.item.style.transform = null;
    var evt = new CustomEvent('export:pages-mutated');
    document.dispatchEvent(evt);
  }

  reorderAllItems() {
    let index = 0

    for (const kpiItem of document.querySelectorAll("div.row.draggable-kpi")) {
      kpiItem.querySelector("input.export-kpi-weight").value = index
      index++
    }
  }

  /**
   * Hides the item that contains the current event target
   * and shows and unhide floating button to unhide it.
   * 
   * @param {Event} e click event on a checkbox
   */
  hideItem(e) {
    const item = this.itemTargets.find(element => element.contains(e.target))

    if (item) {
      item.classList.add("form-item-to-hide")
      const kpiButton = KPIButton.buildFromItem(item)
      item.appendChild(kpiButton.button)

      createPopper(item, kpiButton.button, {
        placement: "left-start",
        modifiers: [{ name: 'offset', options: { offset: [-15, 20] } }]
      }) // popper.js is a library for tooltip positioning

      this.buttons.push(kpiButton)
      this.updatePositions()
    }
  }

  /**
   * @param {*} e 
   */
  deleteItem(e) {
    const item = this.itemTargets.find(element => element.contains(e.target))
    if (item) {
      item.classList.add("form-item-to-hide")
      const kpiButton = KPIButton.buildFromItem(item, KPIButton.types.RESTORE)
      item.appendChild(kpiButton.button)

      createPopper(item, kpiButton.button, {
        placement: "right-start",
        modifiers: [{ name: 'offset', options: { offset: [-15, 20] } }]
      }) // popper.js is a library for tooltip positioning

      this.buttons.push(kpiButton)
      this.updatePositions()
    }
  }

  /**
   * Updates all the positions corresponding to the place in which the
   * buttons are located with respect to the y axis.
   * 
   * The positions property is an object that contains a position in the
   * y axis and all the buttons that are positioned in the same place.
   * 
   * Every time this method is called, the positions data is erased
   * and calculated and their listeners are disposed.
   */
  updatePositions() {
    this.clearPositions()
    let unusedButtons = []

    for (const button of this.buttons) {
      if (button.isUnused()) {
        unusedButtons.push(button)
        continue
      }
      this.addButtonToPosition(button)
    }

    this.positions.forEach(position => position.attachEventListeners(e => this.unHideItem(e)))
    this.removeButtons(unusedButtons)

    var evt = new CustomEvent('export:pages-mutated');
    document.dispatchEvent(evt);
  }

  /**
   * Add a given button to a PositionedButtons object that
   * has its same position and type.
   * 
   * @param {KPIButton} button 
   */
  addButtonToPosition(button) {
    const position = button.buttonPosition()

    let positionElement = this.positions.find(e => e.position == position && e.type == button.type)
    if (!positionElement) {
      positionElement = new PositionedButtons(position, button.type)
      this.positions.push(positionElement)
    }

    positionElement.addElement(button)
  }

  /**
   * Removes a list of given KPI Buttons.
   * 
   * @param {Array<KPIButton>} buttons
   */
  removeButtons(buttons) {
    this.buttons = this.buttons.filter(button => buttons.indexOf(button) == -1)
  }

  /**
   * Removes all positions and their listeners.
   */
  clearPositions() {
    this.positions.forEach(p => p.disposeListeners())
    this.positions = []
  }

  /**
   * Unhides an item and removes their hide and restore Buttons.
   * 
   * @param {Event} e click event on a checkbox
   */
  unHideItem(e) {
    const item = this.itemTargets.find(element => element.contains(e.target))
    if (item) {
      this.uncheckCheckboxes(item)
      item.classList.remove('form-item-to-hide')
      this.removeFloatingButtons(item)

      this.updatePositions()
      this.autosize(item)
    }
  }

  /**
   * Removes the floating hide and restore buttons.
   * 
   * @param {HTMLElement} item 
   */
  removeFloatingButtons(item) {
    /* all buttons are selected instead of just one,
       because when the form is saved, the current selected kpis to hide or delete, 
       keep their buttons and aren't removed */
    const hiderButtons = item.querySelectorAll(".form-button-show")
    hiderButtons.forEach(button => button.remove())
    const restoreButtons = item.querySelectorAll(".form-button-restore")
    restoreButtons.forEach(button => button.remove())
  }

  /**
   * Unhides an item with an animation.
   * 
   * @param {HTMLElement} item 
   */
  animateUnhide(item) {
    item.classList.remove("form-item-to-hide")
    item.classList.add('form-item-unhiding')
    setTimeout(() => item.classList.remove('form-item-unhiding'), 1000)
  }

  /**
   * Sets the values of hidden and delete to false.
   * 
   * @param {HTMLElement} item 
   */
  uncheckCheckboxes(item) {
    const hiddenCheckbox = $(item).find("input[type='checkbox'].hide-checkbox")[0]
    const deleteCheckbox = $(item).find("input[type='checkbox'].delete-checkbox")[0]
    setTimeout(() => {
      hiddenCheckbox.checked = false
      deleteCheckbox.checked = false
    }, 1)
  }

  autosize(item) {
    let inputs = $(item).find('textarea')
    for (let i = 0; i < inputs.length; i++) {
      let ctrl = this.application.getControllerForElementAndIdentifier(inputs[i], "autosize")
      if (ctrl) {
        ctrl.autosize()
      }
    }
  }
}
