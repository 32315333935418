import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = ["item"]

  upgradeClass = "form-item-to-upgrade"
  deleteClass = "form-item-to-delete"

  connect() { 
    // Set initial states of items based on its delete or destroy checkbox
    for (const item of this.itemTargets) {
      const deleteCheckbox = $(item).find("input[type='checkbox'][name*='delete']")
      if (deleteCheckbox[0]) {
        item.classList.toggle(this.deleteClass, deleteCheckbox[0].checked)
      }

      const upgradeCheckbox = $(item).find("input[type='checkbox'][name*='upgrade']")
      if (upgradeCheckbox[0]) {
        item.classList.toggle(this.upgradeClass, upgradeCheckbox[0].checked)
      }
    }
  }

  markItemForDeletion(e) {
    if (typeof e.target.checked != "boolean") return

    for (const item of this.itemTargets) {
      if (item.contains(e.target)) {
        item.classList.toggle(this.deleteClass, e.target.checked)
        this.deactivateCheckbox({item: item, upgradeCheckbox: true})
        break
      }
    }
  }

  removeItem(e) {
    e.preventDefault()

    for (const item of this.itemTargets) {
      if (item.contains(e.target)) {
        item.remove()
        break
      }
    }

    document.dispatchEvent(new CustomEvent("export:pages-mutated"))
  }

  markItemForUpgrade(e) {
    if (typeof e.target.checked != "boolean") return

    for (const item of this.itemTargets) {
      if (item.contains(e.target)) {
        item.classList.toggle(this.upgradeClass, e.target.checked)
        this.deactivateCheckbox({item: item, deleteCheckbox: true})
        break
      }
    }
  }

  deactivateCheckbox({item, deleteCheckbox = false, upgradeCheckbox = false}) { 
    if (deleteCheckbox) {
      const itemCheckbox = $(item).find("input[type='checkbox'][name*='delete']")
      if (itemCheckbox[0]) {
        itemCheckbox[0].checked = false
        item.classList.remove(this.deleteClass)
      }
    } else if (upgradeCheckbox) {
      const itemCheckbox = $(item).find("input[type='checkbox'][name*='upgrade']")
      if (itemCheckbox[0]) {
        itemCheckbox[0].checked = false
        item.classList.remove(this.upgradeClass)
      }
    }
  }
}
