import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    $(this.element).tooltip({
      template: this.buildTemplate(),
      trigger: this.isClickable() ? 'manual' : 'hover focus'
    });

    if (this.isClickable()) {
      this.mouseEnterHandler = this.showTooltip.bind(this);
      this.mouseLeaveHandler = this.hideTooltip.bind(this);
      this.clickHandler = this.openUrl.bind(this);

      this.element.addEventListener('mouseenter', this.mouseEnterHandler);
      this.element.addEventListener('mouseleave', this.mouseLeaveHandler);
      this.element.addEventListener('click', this.clickHandler);
    }
  }

  disconnect() {
    $(this.element).tooltip('dispose');
    this.element.removeEventListener('mouseenter', this.mouseEnterHandler);
    this.element.removeEventListener('mouseleave', this.mouseLeaveHandler);
    this.element.removeEventListener('click',this.clickHandler);
  }

  showTooltip() {
    this.timeout = setTimeout(() => {
      this.tooltipVisible = true;
      $(this.element).tooltip('show');
    }, 1000);  
  }

  hideTooltip() {
    this.tooltipVisible = false;
    $(this.element).tooltip('hide');
    clearTimeout(this.timeout);
  }

  openUrl(event) {
    if (!this.tooltipVisible) return;
    event.stopPropagation();
    event.preventDefault();

    const url = this.data.get('url');
    window.open(url);
  }

  isClickable() {
    return this.data.get('clickable') == 'true'
  }

  buildTemplate() {
    const type = this.data.get("type");
    const tooltipType = type ?  `tooltip-${this.data.get("type")}` : "";

    return `
      <div class="tooltip ${tooltipType}" role="tooltip">
        <div class="arrow"></div>
        <div class="tooltip-inner"></div>
      </div>
    `;
  }
}
