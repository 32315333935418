import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "query", "results" ]

  initialize() {
    this.debounceTimeout = {}
  }

  disconnect() {
    this.reset()
  }

  handleKey(e) {
    if (e.keyCode === 27) { // esc
      this.reset()
    }
  }

  hide() {
    setTimeout(this.reset.bind(this), 200);
  }

  fetchResults() {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout)
    }

    if (this.query == "") {
      this.reset()
      return
    }

    this.debounceTimeout = setTimeout(() => {
      if (this.query == this.previousQuery) {
        return
      }
      this.previousQuery = this.query

      const url = new URL(this.data.get("url"))
      url.searchParams.append("query", this.query)
      url.searchParams.append("results_template", this.data.get("results-template"))

      fetch(url)
        .then(response => response.text())
        .then(html => {
          this.resultsTarget.innerHTML = html
        })
    }, 350)
  }

  reset() {
    this.resultsTarget.innerHTML = ""
    this.queryTarget.value = ""
    this.previousQuery = null
    clearTimeout(this.debounceTimeout)
  }

  navigateResults(event) {
    if (this.searchResultsController) {
      this.searchResultsController.navigateResults(event)
    } else if (this.selectSearchController) {
      this.selectSearchController.navigateResults(event)
    }
  }

  get query() {
    return this.queryTarget.value
  }

  get searchResultsController() {
    return this.application.getControllerForElementAndIdentifier(this.resultsTarget.firstElementChild, "search-results")
  }

  get selectSearchController() {
    return this.application.getControllerForElementAndIdentifier(this.resultsTarget.firstElementChild, "select-search")
  }
}
