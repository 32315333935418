import { Controller } from 'stimulus'


export default class extends Controller {
  static targets = ['selector', 'field']

  changeTemplate() {
    this.fieldTarget.value = this.selectorTarget.value
  }
}
