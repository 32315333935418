import { Controller } from 'stimulus'


export default class extends Controller {
  initialize() {
    window.onscroll = this.onScroll.bind(this);
  }

  connect() {
    this.stickyOffset = this.element.offsetTop;
  }

  onScroll() {
    if (window.pageYOffset >= this.stickyOffset) {
      this.element.classList.add("sticky")
    } else {
      this.element.classList.remove("sticky");
    }
  }
}
