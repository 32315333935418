import SearchResultsController from "./search_results_controller";

export default class extends SearchResultsController {
  static targets = [ "result" ]

  connect() {
    super.connect()
    
    this.selectTarget = document.querySelector(this.data.get("select-element"))
    this.template = document.querySelector(this.data.get("template"))
    this.list = document.querySelector(this.data.get("list"))
  }

  /**
   * Function triggered when an element in the results container is selected (click).
   */
  select(event) {
    var searchedElement = ""

    this.resultTargets.forEach((element, index) => {
      if (element.contains(event.target)) {
        searchedElement = element
      }
    })

    var searchedElementID = searchedElement.getAttribute('data-element-id')
    var searchedElementName = searchedElement.querySelector('.search__result__name').textContent

    this.appendElementToSelectedList(searchedElementID, searchedElementName)
    this.changeOptionStatus(searchedElementID, true)
    event.preventDefault()
  }

  /**
   * Appends an element only if it is not already selected.
   */
  appendElementToSelectedList(elementID, elementName) {
    if (!this.isElementSelected(elementID)) {
      var template = this.template.cloneNode(true)

      template.setAttribute('data-element-id', elementID)
      template.querySelector('.result-element__name').innerHTML = elementName
      template.querySelector('.result-element__cancel').addEventListener("click", event => this.removeSelection(event))
      
      template.removeAttribute('style')
      template.removeAttribute('id')

      this.list.appendChild(template)
    }
  }

  /**
   * Returns true if a element with a given ID is
   * already selected in the select multiple element.
   */
  isElementSelected(elementID) {
    var options = this.selectTarget.options
    var element = Array.from(options).find(option => option.value == elementID)
    return element ? element.selected : false
  }

  /**
   * Remove an element from the selected elements and
   * uncheck the selection of the element in the select multiple list.
   */
  removeSelection(event) {
    var element = event.target.parentElement
    var elementID = element.getAttribute('data-element-id')

    this.changeOptionStatus(elementID, false)
    element.remove()
  }

  /**
   * Search a given ID in the select multiple list.
   * Then asign the selected value given by parameter. 
   */
  changeOptionStatus(elementID, status) {
    var options = this.selectTarget.options
    var element = Array.from(options).find(option => option.value == elementID)
    if (element) {
      element.selected = status
    }
  }

  goToSelectedResult() {
    this.resultTargets[this.currentResultIndex].click()
  }
}
