import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.element.addEventListener('click', this.save.bind(this));
    this.afterSubmittingHandler = this.openPageInNewTab.bind(this);
    this.eventToListen = this.data.get("event");
    this.saving = false
  }

  save(event) {
    event.preventDefault();

    if (this.hasAlreadyBeenSaved()) {
      this.openPageInNewTab();
      return;
    }
    if (this.saving) return;

    this.replaceInnerHTMLToLoadingIcon();
    this.submitForm();

    document.addEventListener(this.eventToListen, this.afterSubmittingHandler);
    setTimeout(this.finishProcess.bind(this), 5000);
    this.saving = true;
  }

  hasAlreadyBeenSaved() {
    const checkElement = document.querySelector(this.data.get("check-query"));
    const isSaved = checkElement.getAttribute(this.data.get("check-attribute"));

    return isSaved === "true";
  }

  submitForm() {
    document.querySelector("button[type='submit']").click();
  }

  openPageInNewTab(event) {
    if (event === undefined || event.detail.valid) window.open(this.element.href, '_blank');
    this.finishProcess();
  }

  /**
   * Replaces the inner html of the element with its original html content,
   * and removes the event listener.
   */
  finishProcess() {
    this.replaceLoadingIconToPreviousHTML();
    document.removeEventListener(this.eventToListen, this.afterSubmittingHandler);
    this.saving = false;
  }

  replaceInnerHTMLToLoadingIcon() {
    this.innerHTML = this.element.innerHTML;
    this.element.innerHTML = '<i class="fas fa-spinner fa-spin"></i>';
  }

  replaceLoadingIconToPreviousHTML() {
    this.element.innerHTML = this.innerHTML ? this.innerHTML : this.element.innerHTML;
  }
}
