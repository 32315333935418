import $ from 'jquery';

export class KPIButton {
  static types = {
    SHOW: 'show',
    RESTORE: 'restore',
  };

  /**
   * Creates a KPIButton with its name
   * and value as strings.
   * 
   * @param {String} name 
   * @param {String} placeholder
   * @param {String} value
   * @param {HTMLElement} container
   * @param {KPIButton.types} type
   */
  constructor(name, placeholder, value, container, type) {
    this._name = name;
    this.placeholder = placeholder;
    this._value = value;
    this.container = container;
    this.type = type ? type : KPIButton.types.SHOW;

    this.button = this.buildFloatingShowButton();
  }

  /**
   * Creates and returns a KPIButton from a given html
   * item that contains two inputs with classes `.export-kpi-name`
   * and `.export-kpi-value`.
   * 
   * @param {HTMLElement} item 
   * @param {KPIButton.types} type
   * @returns {KPIButton} button
   */
  static buildFromItem(item, type) {
    const name = item.querySelector(".export-kpi-name").value;
    const placeholder = item.querySelector(".export-kpi-name").placeholder;
    const value = item.querySelector(".export-kpi-value").value;

    return new this(name, placeholder, value, item, type);
  }

  /**
   * Returns the y position of the button.
   * 
   * @returns {number} position.
   */
  buttonPosition() {
    return this.container.getBoundingClientRect().top;
  }

  /**
   * Returns true if the element does not exist
   * in the DOM
   * 
   * @returns {boolean} isUnused.
   */
  isUnused() {
    return !document.contains(this.button);
  }

  /**
   * Returns the value of the value property or "---" in case
   * is not defined.
   * 
   * @returns {String} value.
   */
  get value() {
    if (!this._value) return "---";
    return this._value;
  }

  /**
   * Sets the value of the value property.
   * 
   * @param {String} value.
   */
  set value(value) {
    this._value = value;
  }

  /**
   * Returns the value of the name property or a placeholder
   * in case is not defined.
   * 
   * @returns {String} value.
   */
  get name() {
    if (!this._name) return this.placeholder;
    return this._name;
  }

  /**
   * Sets the value of the name property.
   * 
   * @param {String} name.
   */
  set name(name) {
    this._name = name;
  }

  /**
   * Creates a button that has the purpose of unhiding items.
   * 
   * @returns {HTMLButtonElement} unhide button
   */
  buildFloatingShowButton() {    
    const html = `
    <div class='${this.getClassFromType()} shadow-sm'>
      <i class='fas ${this.getIconFromType()}'></i>
    </div>`;

    return $($.parseHTML(html))[1];
  }

  getIconFromType() {
    switch(this.type) {
      case KPIButton.types.SHOW:
        return "fa-eye";
      case KPIButton.types.RESTORE:
        return "fa-trash-restore";
    }
  }

  getClassFromType() {
    switch (this.type) {
      case KPIButton.types.SHOW:
        return "form-button-show";
      case KPIButton.types.RESTORE:
        return "form-button-restore";
    }
  }
}
