import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.setAttribute('autocomplete', this.isWindowsOS() ? 'off' : 'nope');
  }

  isWindowsOS() {
    return navigator.appVersion.indexOf('Win') != -1;
  }
}
